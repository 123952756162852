<template>
    <div>
        <v-dialog
            v-model="dialogShow"
            fullscreen
            hide-overlay
            transition="scroll-x-reverse-transition"
            scrollable
            style="overflow-x: hidden">
            <v-card tile v-if="model">
                <v-card-title class="pa-6">
                    <v-row no-gutters>

                        <v-col class="col-12 ma-auto ml-0">
                            <div class="pl-0 font-weight-bold">Sanzione</div>
                            <div class="pl-0 font-weight-bold" style="font-size: 27px">{{ model.full_name }}</div>
                            <div class="pl-0 font-weight-bold">{{ model.description }}</div>
                        </v-col>
                    </v-row>
                 
                </v-card-title>

                <v-card-text style="overflow-x: hidden; margin-top: 20px" class="body-page" v-if="dialogShow">
                    <v-container class="px-0 justify-center" fluid v-if="!confirmDelete">
                        
                        <template>
                            <v-row class="ma-auto d-flex pt-6">
                                <v-icon>mdi-text</v-icon>
                                <div class="d-block">
                                    <div class="pl-2 caption">Descrizione</div>
                                    <div class="pl-2">{{ model.description }}</div>
                                </div>
                            </v-row>
                            <v-row class="ma-auto d-flex pt-6">
                                <v-icon>mdi-text</v-icon>
                                <div class="d-block">
                                    <div class="pl-2 caption">Norma Violata</div>
                                    <div class="pl-2">{{ model.broken_rules }}</div>
                                </div>
                            </v-row>
                            <v-row class="ma-auto d-flex pt-6">
                                <v-icon>mdi-text</v-icon>
                                <div class="d-block">
                                    <div class="pl-2 caption">Sanzioni Previste</div>
                                    <div class="pl-2">{{ model.penalties_provided }}</div>
                                </div>
                            </v-row>
                            <v-row class="ma-auto d-flex pt-6">
                                <v-icon>mdi-currency-eur</v-icon>
                                <div class="d-block">
                                    <div class="pl-2 caption">Sanzioni Ridotta</div>
                                    <div class="pl-2">{{ model.reduced_penalty }}</div>
                                </div>
                            </v-row>
                            <v-row class="ma-auto d-flex pt-6">
                                <v-icon>mdi-text</v-icon>
                                <div class="d-block">
                                    <div class="pl-2 caption">Note</div>
                                    <div class="pl-2">{{ model.note }}</div>
                                </div>
                            </v-row>
                        </template>


                    </v-container>

                    <v-container v-else>
                        <v-row class="mx-0 justify-center pt-6">
                            <div class="caption" >Sei sicuro di voler eliminare questa Sanzione?</div>
                        </v-row>
                    </v-container>

                </v-card-text>

                <v-card-actions class="pa-6 pb-10 justify-center elevation-5" v-if="!confirmDelete">
                    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
                    <v-tooltip top>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn v-on="{ ...tooltip }" color="background-1" fab small @click="closeDialog()">
                                <v-icon>mdi-keyboard-backspace</v-icon>
                            </v-btn>
                        </template>
                        <span>Chiudi</span>
                    </v-tooltip>
                    <v-btn color="error" outlined  height="40" class="ml-4" @click="confirmDestroy()">
                        Elimina
                    </v-btn>                    
                    <v-btn color="secondary" min-width="100px" height="40" outlined class="ml-4" @click="edit(model)">
                        Modifica
                    </v-btn>             
                    
                </v-card-actions>

                <v-card-actions class="pa-6 pb-10 justify-center elevation-5" v-else>
                    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
                    <v-tooltip top>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn v-on="{ ...tooltip }" color="background-1" fab small @click="closeDialog()">
                                <v-icon>mdi-keyboard-backspace</v-icon>
                            </v-btn>
                        </template>
                        <span>Chiudi</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn v-on="{ ...tooltip }" color="secondary" :loading="loading" min-width="100px" height="40" outlined class="ml-4" @click="deleteViolation()">
                                Conferma
                            </v-btn>
                        </template>
                        Conferma
                    </v-tooltip>
                </v-card-actions>

            </v-card>
        </v-dialog>                

        <v-dialog
            v-model="dialogEditor"
            fullscreen
            hide-overlay
            transition="scroll-x-reverse-transition"
            scrollable
            style="overflow-x: hidden">
            <v-card tile v-if="model">
                <v-card-title class="pa-6" style="position: fixed">
                    <v-row no-gutters>

                        <v-col class="col-12 ma-auto ml-0">
                            <template v-if="confirmDelete">
                                <div class="pl-0 font-weight-bold">Elimina Sanzione</div>
                                <div class="pl-0 font-weight-bold" style="font-size: 27px">{{ model.full_name }}</div>
                            </template>
                            <template v-else-if="model.id">
                                <div class="pl-0 font-weight-bold">Modifica Sanzione</div>
                                <div class="pl-0 font-weight-bold" style="font-size: 27px">{{ model.full_name }}</div>
                                <div class="pl-0 font-weight-bold">{{ model.description }}</div>
                            </template>
                            <template v-else>
                                <div class="pl-0 font-weight-bold" style="font-size: 27px">Nuova Sanzione</div>
                            </template>
                        </v-col>
                    </v-row>
                </v-card-title>
                
                <v-card-text style="overflow-x: hidden; margin-top: 100px" class="body-page pa-0">
                    <v-container class="px-0 justify-center" fluid>
                        
                        <validation-observer ref="fields">

                            <v-row class="pb-2 mx-0 pt-2">
                                <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                                    <validation-provider name="Numero" rules="required" v-slot="{ errors }">
                                        <v-text-field
                                            type="text"
                                            label="Numero*"
                                            v-model="model['number']"
                                            :error-messages="errors"
                                            outlined
                                            dense
                                            ref="number"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>

                                <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                                    <validation-provider name="Sezione" rules="required" v-slot="{ errors }">
                                        <v-autocomplete class="font-weight-regular"
                                            label="Sezione*"
                                            v-model="model['section_id']"
                                            item-value="id"
                                            item-text="name"
                                            :error-messages="errors"
                                            clearable
                                            small-chips
                                            :items="info.sections"
                                            dense
                                            outlined>
                                        </v-autocomplete>
                                    </validation-provider>
                                </v-col>
                            
                                <v-col class="col-12 py-0 pt-3 px-6">
                                    <validation-provider name="Descrizione" rules="max:500" v-slot="{ errors }">
                                        <v-textarea
                                            label="Descrizione"
                                            name="description"
                                            v-model="model['description']"
                                            :error-messages="errors"
                                            type="text"
                                            outlined
                                            dense
                                            counter
                                            ref="description"
                                        ></v-textarea>
                                    </validation-provider>
                                </v-col>


                                <v-col class="col-12 py-0 pt-3 px-6">
                                    <validation-provider name="Norme Violate" rules="max:255" v-slot="{ errors }">
                                        <v-textarea
                                            label="Norme Violate"
                                            name="broken_rules"
                                            v-model="model['broken_rules']"
                                            :error-messages="errors"
                                            type="text"
                                            outlined
                                            dense
                                            counter
                                            ref="broken_rules"
                                        ></v-textarea>
                                    </validation-provider>
                                </v-col>


                                <v-col class="col-12 py-0 pt-3 px-6">
                                    <validation-provider name="Sanzioni Previste" rules="max:255" v-slot="{ errors }">
                                        <v-textarea
                                            label="Sanzioni Previste"
                                            name="penalties_provided"
                                            v-model="model['penalties_provided']"
                                            :error-messages="errors"
                                            type="text"
                                            outlined
                                            dense
                                            counter
                                            ref="penalties_provided"
                                        ></v-textarea>
                                    </validation-provider>
                                </v-col>

                                <v-col class="col-12 py-0 px-6 pt-4">
                                    <validation-provider name="Sanzione Ridotta" rules="required|double" v-slot="{ errors }">
                                        <v-text-field
                                            type="number"
                                            label="Sanzione Ridotta*"
                                            v-model="model['reduced_penalty']"
                                            :error-messages="errors"
                                            append-icon="mdi-currency-eur"
                                            outlined
                                            dense
                                            ref="reduced_penalty"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>


                                <v-col class="col-12 py-0 pt-3 px-6">
                                    <validation-provider name="Note" rules="max:500" v-slot="{ errors }">
                                        <v-textarea
                                            label="Note"
                                            name="note"
                                            v-model="model['note']"
                                            :error-messages="errors"
                                            type="text"
                                            outlined
                                            dense
                                            counter
                                            ref="note"
                                            ></v-textarea>
                                    </validation-provider>
                                </v-col>           
                            </v-row>
                            
                        </validation-observer> 


                    </v-container>

                </v-card-text>

                <v-card-actions class="pa-6 pb-10 justify-center elevation-5">
					<v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
					<v-tooltip top>
						<template v-slot:activator="{ on: tooltip }">
							<v-btn v-on="{ ...tooltip }" color="background-1" fab small @click="closeDialog()">
								<v-icon>mdi-keyboard-backspace</v-icon>
							</v-btn>
						</template>
						<span>Chiudi</span>
					</v-tooltip>
					<v-btn v-if="model.id" color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="storeViolation()">Salva</v-btn>
					<v-btn v-else color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="storeViolation()">Crea</v-btn>
				</v-card-actions>


            </v-card>
        </v-dialog>

    </div>        
   
</template>

<script>


export default {
	name: 'violation-crud',
	props: {
		info: Object,
	},
        
	data() {
		return {
			tab: null,
			type: null,
			model: {},
			saved: false,
			loading: false,
			dialogShow: false,
			dialogEditor: false,
			confirmDelete: false,
		}
	},

	methods: {

		closeDialog() {
			this.dialogShow = false
			this.dialogEditor = false
			this.confirmDelete = false
			this.model = _.stubObject()
		},

		edit(item) {
			this.dialogShow = false
			this.dialogEditor = true
			this.model = {...item}
			this.loading = false
		},

		show(id) {
			this.axios.get('violations/' + id).then(response => {
					
				this.dialogShow = true
				this.model = response.data.data
				this.loading = false

			}, response => {
				this.loading = false
			})
		},

		create() {
			this.dialogEditor = true
		},
            
		storeViolation() {
			this.$refs.fields.validate().then(result => {
				if (!result) {
					return false
				}
				this.loading = true

				if(this.model.id) {
					this.axios.put('violations/' + this.model.id, this.model).then(response => {
						this.loading = false
						this.$eventBusUtility.$emit('refreshTable', 'violation')
						this.closeDialog()
					}, response => {
						this.loading = false
					})					
				} else {
					this.axios.post('violations', this.model).then(response => {
						this.loading = false
						this.$eventBusUtility.$emit('refreshTable', 'violation')
						this.closeDialog()

					}, response => {
						this.loading = false
					})
				}


			}).catch(() => {

			})                
		},

		deleteViolation() {
			this.loading = true
			this.axios.delete('violations/' + this.model.id).then(response => {
				this.loading = false
				this.$eventBusUtility.$emit('refreshTable', 'violation')
				this.closeDialog()
			}, response => {
				this.loading = false
			})
		},

		confirmDestroy() {
			this.confirmDelete = true
			this.dialogShow = true
		},

	},

	mounted() {
		this.$eventBusUtility.$on('showViolation', (item) => {
			this.show(item.id)
		}),

		this.$eventBusUtility.$on('editViolation', (item) => {
			this.edit(item)
		}),
               
		this.$eventBusUtility.$on('createViolation', () => {
			this.create()
		})
            
		this.$eventBusUtility.$on('destroyViolation', (item) => {
			this.confirmDestroy()
			this.$set(this, 'model', item)
		})
	},


	beforeDestroy() {
		this.$eventBusUtility.$off('showViolation')
		this.$eventBusUtility.$off('editViolation')
		this.$eventBusUtility.$off('createViolation')
		this.$eventBusUtility.$off('destroyViolation')
	},    

}
</script>

<style>

</style>